import { AxiosResponse } from 'axios';
import {ENTITY_CLIENT} from "@/api/misc/EntityClient";

/**
 * deals with all the requests that have to do with accounts
 */
export default class MerchantRepository {
    /**
     * gets an account based on the token
     */
    public static getDashBoardLink(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/merchant/${companyId}/payments/profile/setup/dashboard`);
    }

    public static getStatus(companyId: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.get(`api/merchant/${companyId}/payments/profile/setup/status`);
    }

    public static postSetup(companyId: string, url: string): Promise<AxiosResponse> {
        return ENTITY_CLIENT.post(`api/merchant/${companyId}/payments/profile/setup`, {
            'refreshUrl': url,
            'returnUrl': url
        });
    }
}
