
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import Company from "@/models/Company";
import MerchantRepository from "@/api/repositories/MerchantRepository";

/**
 * connection status of the stripe connect
 */
export enum StripeConnectionStatus {
  NOT_STARTED = 'NOT_STARTED',
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED'
}

/**
 * shows the detailed state of the connection, used to show more detailed descriptions
 */
enum StripeDetailedStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED',
  INPUT_REQUIRED = 'INPUT_REQUIRED',
  ACCOUNT_CLOSED = 'ACCOUNT_CLOSED',
  NOT_STARTED = 'NOT_STARTED'
}

@Component
export default class StripeConnectComponent extends Vue {
  @Prop({required: true})
  private company!: Company;

  /**
   * loading flag
   * @private
   */
  private isLoading = false;

  /**
   * has error flag
   * @private
   */
  private hasError = false;

  /**
   * flag that indicates if the user is already connected to stripe connect
   * @private
   */
  private connectionStatus: StripeConnectionStatus = StripeConnectionStatus.NOT_STARTED;

  /**
   * flag that indicates which kind of connection status is used in detail
   * @private
   */
  private detailedConnectionStatus: StripeDetailedStatus = StripeDetailedStatus.NOT_STARTED;

  /**
   * link to the dashboard (used when user is connected to stripe connect)
   * @private
   */
  private dashboardLink = '';

  /**
   * return url that is used when user finished connected to stripe connect
   * @private
   */
  private returnUrl = process.env.VUE_APP_STRIPE_RETURN_URL! + this.company.id + '?scc=true';

  /**
   * gets the status of the user, if user is already connected to stripe, the dashboard link is loaded
   * @private
   */
  private async created() {
    await this.loadStatus();
  }

  /**
   * gets the button text
   * @private
   */
  private get buttonText() {
    switch(this.connectionStatus) {
      case StripeConnectionStatus.NOT_STARTED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.BUTTON_NOT_STARTED').toString();
      case StripeConnectionStatus.NOT_COMPLETED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.BUTTON_NOT_COMPLETE').toString();
      case StripeConnectionStatus.COMPLETED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.BUTTON_COMPLETE').toString();
    }
  }

  /**
   * returns text based on detailed status
   * @private
   */
  private get descriptionText() {
    switch(this.detailedConnectionStatus) {
      case StripeDetailedStatus.COMPLETED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_COMPLETE');
      case StripeDetailedStatus.PENDING:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_PENDING');
      case StripeDetailedStatus.ACCOUNT_CLOSED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_ACCOUNT_CLOSED');
      case StripeDetailedStatus.INPUT_REQUIRED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_INPUT_REQUIRED');
      case StripeDetailedStatus.VERIFICATION_REQUIRED:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_VERIFICATION_REQUIRED');
      default:
        return this.$t('EDIT_PROFILE.CHANGE_COMPANY_PAYMENT.STRIPE_CONNECT.DESCRIPTION_NOT_STARTED');
    }
  }

  /**
   * loads stripe status of the user
   * @private
   */
  private async loadStatus() {
    try {
      this.isLoading = true;
      const {data} = await MerchantRepository.getStatus(this.company.id);
      this.connectionStatus = data['status']!;
      this.detailedConnectionStatus = data['detailedStatus']['status'][0]!;

      if(data['status'] === 'COMPLETED') {
        await this.getDashboardLink();
      }

      this.hasError = false;
    } catch(_) {
      this.hasError = true;
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.STATUS.ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * checks if the user is already connected to stripe and either starts connecting or shows dashboard
   * @private
   */
  private async handleButtonClick() {
    switch(this.connectionStatus) {
      case StripeConnectionStatus.COMPLETED:
        await this.openDashboard();
        return;
      default:
        await this.tryStripeConnect();
        return;
    }
  }

  /**
   * tries to get the set up url of stripe, opens new tab with the url to start connecting the user with stripe
   * @private
   */
  private async tryStripeConnect() {
    try {
      this.isLoading = true;

      // gets stripe connect link
      const response = await MerchantRepository.postSetup(this.company.id, this.returnUrl);

      // creates virtual link that opens stripe connect link in new tab, removes virtual afterwards
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = response.data['onboardingUrl'];
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.WRONG_ADDRESS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.CONNECT.ERROR');
            break;
        }
      });
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * gets the dashboard link (used when user is connected to stripe connect)
   * @private
   */
  private async getDashboardLink() {
    try {
      const dashboardData = await MerchantRepository.getDashBoardLink(this.company.id);
      this.dashboardLink = dashboardData.data['loginUrl'];
    } catch(_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.DASHBOARD.ERROR');
    }
  }

  /**
   * opens the dashboard link in new tab
   * @private
   */
  private async openDashboard() {
    try {
      this.isLoading = true;
      await window.open(this.dashboardLink, '_blank')?.focus();
    } finally {
      this.isLoading = false;
    }
  }
}
