import { render, staticRenderFns } from "./NavigationDrawer.component.vue?vue&type=template&id=b40c09ec&scoped=true&"
import script from "./NavigationDrawer.component.vue?vue&type=script&lang=ts&"
export * from "./NavigationDrawer.component.vue?vue&type=script&lang=ts&"
import style0 from "./NavigationDrawer.component.vue?vue&type=style&index=0&id=b40c09ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b40c09ec",
  null
  
)

export default component.exports