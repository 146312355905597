
import {Component, Prop, Watch} from 'vue-property-decorator';
import {NavigationMenuItemInterface} from '@/interfaces/NavigationMenuItem.interface';
import {ROUTE_LOGIN, ROUTE_PROFILE} from "@/router/routes";
import Vue from 'vue';
import {NavigationMenuCategoryInterface} from "@/interfaces/NavigationMenuCategory.interface";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME, ApplicationStoreGetters} from "@/store/application.store";
import Company from "@/models/Company";
import {NavigationMenuCategory} from "@/misc/NavigationMenuItems";
import {LocationStatus} from "@/enum/LocationStatus.enum";

//@ts-ignore
import {version} from '../../../package';
import Address from "@/models/Address";
import {AccountType} from "@/enum/AccountType.enum";
import MerchantRepository from "@/api/repositories/MerchantRepository";
import {StripeConnectionStatus} from "@/components/Payment/StripeConnect.component.vue";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {
    LogoComponent: () => import(
        /* webpackChunkName: "LogoComponent" */
        '@/components/Logo.component.vue'
        ),
  }
})
export default class NavigationDrawerComponent extends Vue {
  @Prop({ required: true })
  private navigationMenuItems!: NavigationMenuCategoryInterface[];

  @Prop({ required: true })
  private onLogout!: () => void;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private currentCompany!: Company;

  private showNavigationDrawer: boolean = false;

  private stripeConnectionStatus: StripeConnectionStatus = StripeConnectionStatus.NOT_STARTED;

  private async created() {
    if(!this.$isLoggedIn() && this.$route.name !== ROUTE_LOGIN) {
      await this.$router.push({name: ROUTE_LOGIN});
    }

    await this.loadStatus();
  }

  private onNavItemClick(item: NavigationMenuItemInterface) {
    this.$router.push({name: item.route, params: {id: this.$route.params.id}}).catch(() => {return;});
  }

  private onInstructionsClicked() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = process.env.VUE_APP_INSTRUCTIONS!;
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  private onRatenkaufClicked() {
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.href = "https://mehrweg-abo.de";
    a.target = '_blank';
    a.click();
    document.body.removeChild(a);
  }

  /**
   * is called when the user clicks on the edit profile section, navigates to the profile view
   */
  private onEditProfileClicked() {
    if(this.$route.name === ROUTE_PROFILE) return;
    this.$router.push({name: ROUTE_PROFILE, params: {id: this.$route.params.id}});
  }

  private showCompanyName(categoryName: NavigationMenuCategory): boolean {
    return categoryName === NavigationMenuCategory.COMPANY_PANEL;
  }

  private get appVersion() {
    return version;
  }

  /**
   * checks if an error address badge should be shown
   * @private
   */
  private get showAddressBadge(): boolean {
    // if company wasn't fetched yet or there is no company, just return false
    if(!this.currentCompany?.address) return false;

    // checks if the address or the delivery address is set and has a error as location status
    return this.currentCompany.address?.locationStatus === LocationStatus.ERROR ||
        (this.currentCompany.deliveryAddress?.locationStatus === LocationStatus.ERROR
            && Address.isAddressComplete(this.currentCompany.deliveryAddress));
  }

  private get companyName(): string {
    return this.currentCompany?.name ?? this.$t('EDIT_PROFILE.NO_COMPANY_SELECTED');
  }

  private get isPb() {
    return this.$hasRole(AccountType.PB);
  }
  
  @Watch('currentCompany')
  private async loadStatus() {
    if(!this.currentCompany?.id) return;
    const {data} = await MerchantRepository.getStatus(this.currentCompany.id);
    this.stripeConnectionStatus = data['status']!;
  }

  /**
   * checks if the status of the stripe connection is not completed (to show the badge)
   * @private
   */
  private get stripeNotCompleted() {
    return this.stripeConnectionStatus === StripeConnectionStatus.NOT_COMPLETED;
  }

  /**
   * return url that is used when the stripe onBoarding is finished
   * @private
   */
  private get returnUrl() {
    return process.env.VUE_APP_STRIPE_RETURN_URL! + this.currentCompany?.id + '?scc=true';
  }

  /**
   * tries to get the set up url of stripe, opens new tab with the url to start connecting the user with stripe
   * @private
   */
  private async tryStripeConnect() {
    try {
      // gets stripe connect link
      const response = await MerchantRepository.postSetup(this.currentCompany.id, this.returnUrl);

      // creates virtual link that opens stripe connect link in new tab, removes virtual afterwards
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.href = response.data['onboardingUrl'];
      a.target = '_blank';
      a.click();
      document.body.removeChild(a);
    } catch (e) {
      this.$handleError(e, () => {
        switch (e.status) {
          case 422:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.WRONG_ADDRESS');
            break;
          default:
            this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.STRIPE_CONNECT.CONNECT.ERROR');
            break;
        }
      });
    }
  }
}
